@import '@componentsStyles/colors.scss';
@import '@componentsStyles/zIndex.scss';
@import '@componentsStyles/variables.scss';
@import '@componentsStyles/elevation.scss';
@import '@componentsStyles/mixins.scss';
@import '@components/Typography/Typography.module.scss';
@import 'src/styles/colors';

.dropdown {
  padding: 10px 0;

  .filters {
    overflow: hidden auto;
    @include scrollbar();
    max-height: 50vh;

    &_tabs {
      display: flex;
      gap: 20px;
    }
  }

  .saved_searches {
    height: 100%;

    border-top: 0.2px solid #d1d1d1;

    .search {
      display: flex;
      flex-direction: column;
      padding: 30px 0 0 10px;

      &_list {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 0 22px 10px 30px;
        height: 100%;
        max-height: 100px;
        overflow-y: auto;
        @include scrollbar();

        .wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          line-height: 18px;

          .remove {
            display: none;

            svg {
              fill: $gray-60;
            }

            &:hover {
              svg {
                fill: $gray-100;
              }
            }
          }

          &:hover {
            color: $gold;

            .remove {
              display: flex;
            }
          }
        }

        .remove {
          cursor: pointer;
        }

        &_filter {
          cursor: pointer;
        }
      }

      &_header {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-bottom: 20px;

        &_title {
          font-weight: 700;
        }

        &_icon {
          path {
            fill: $active-side-menu-item;
          }
        }
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px 10px 20px;
    border-top: 0.2px solid #d1d1d1;

    &_reset {
      color: $black;
      cursor: pointer;
    }

    &_apply {
      max-width: 69px;
      padding: 8px 16px;
    }
  }
}

.tabs_content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 20px;
}
