@import 'src/styles/colors';
@import '@componentsStyles/variables';

.dropdownWrapper {
  :global {
    .anticon {
      display: none;
    }
  }
}

.dropdownContainer {
  margin: 15px -11px 0 0;
  max-width: 188px;
  box-shadow:
    -1px -1px 2px rgba(18, 18, 18, 0.05),
    0px 1px 2px rgba(18, 18, 18, 0.2);
  border-radius: 0px 0px 4px 4px;
  background: $white-0;
  display: flex;
  flex-direction: column;
  padding: 20px 10px 10px;
  max-height: 500px;
  overflow: auto;

  &.left {
    margin: 15px 0 0 -11px;
  }

  p {
    margin: 0 0 10px 0;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: $gray-60;
  }

  &Items {
    display: flex;
    flex-direction: column;
    padding: 0 10px;

    &Item {
      cursor: pointer;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: $gray-100;
      padding: 8px 0;
      display: flex;
      align-items: center;
      gap: 8px;

      div {
        display: flex;
        visibility: hidden;
      }

      .selected {
        visibility: visible;

        svg {
          path {
            fill: $gold-100;
          }
        }
      }

      &:hover {
        div {
          visibility: visible;
        }
      }
    }
  }
}
