@import 'src/styles/colors';
@import '@componentsStyles/variables';

.assetCellWrapper {
  gap: 10px;
  width: 200px;
  margin-left: -23px;
  display: grid;
  grid-template-columns: 14px 1fr;

  .flag {
    margin-top: 2px;
  }
}

.assetCellContent {
  max-width: 200px;
  margin-left: -2px;
}

.title {
  margin-right: 8px;
}
