@import '@componentsStyles/colors.scss';
@import '@componentsStyles/zIndex.scss';
@import '@componentsStyles/variables.scss';
@import '@componentsStyles/elevation.scss';
@import '@componentsStyles/mixins.scss';
@import '@components/Typography/Typography.module.scss';

.root {
  width: 100%;
  border: none;
  padding: 0;

  :global {
    .ant-select-item-empty {
      padding: 20px 50px;
      font-weight: 600;
    }

    .rc-virtual-list {
      padding: 10px;
    }

    .ant-select-item-option-active {
      background-color: transparent !important;
    }

    .ant-select-item-option-selected {
      background-color: $white-5 !important;
    }

    .ant-select-item-option:hover {
      background-color: $white-5 !important;
    }
    .ant-select-selection-overflow {
      gap: 2px;
    }
    .ant-select-item-empty {
      font-weight: 600;
    }

    .ant-select-clear {
      opacity: 1 !important;
      margin-right: 10px;
    }

    .ant-select-dropdown {
      top: 61px;
    }
  }
}

.searchContainer {
  position: relative;
  display: flex;

  .disabled {
    svg {
      fill: $gray-20;
    }
  }

  .searchIcon {
    position: absolute;
    bottom: 17px;
    cursor: pointer;
    z-index: 10;
  }

  &:not(.searchMode) {
    :global {
      .ant-select-selection-overflow-item {
        width: 100%;
      }
      .ant-select-selection-search {
        width: 100% !important;
        margin-left: 0 !important;
      }
      .ant-select-selection-search input {
        padding-left: 0 !important;
      }
    }
  }

  &.chipsMode {
    :global {
      .ant-select-selection-overflow-item {
        width: auto;
      }
      .ant-select-selection-search {
        display: none;
      }
      .ant-select-selection-search input {
        padding-left: 0 !important;
      }
      .ant-select-selector {
        cursor: auto !important;
      }
    }
  }

  .ant-select-selector {
    cursor: auto !important;
  }

  &.searchMode {
    :global {
      .ant-select-selection-search-input {
        padding-left: 15px !important;
        max-width: calc(100% - 40px);
      }
    }
  }

  :global {
    .ant-select-selector {
      padding-left: 25px !important;
      max-height: 77px;
      overflow: hidden auto;
      @include scrollbar();
      min-height: 35px;
    }
    .ant-select-selection-placeholder {
      padding-left: var(--placeholder-padding) !important;
    }
  }
}

.select {
  border-bottom: 0.2px solid #d1d1d1;
  width: 100%;
  max-width: 600px;
  padding-bottom: 5px;
  min-height: 40px;
}

.option {
  display: flex;
  align-items: center;
  gap: 11px;
  padding: 10px 0;
  cursor: pointer;

  .optionValue {
    transition: color $transition-duration $transition-timing-function;
  }

  &:hover .optionValue {
    color: $gold;
  }

  .optionDot {
    width: 6px;
    height: 6px;
    background-color: $black;
    border: 4px solid #d0d0d0;
    border-radius: 50%;
  }
}

.chips {
  div {
    line-height: 18px;
    font-weight: 600;
  }
}

.closedSearchContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  min-height: 35px;
  cursor: pointer;
  transition: all $transition-duration $transition-timing-function;

  &:hover {
    svg {
      fill: $black;
    }

    .searchValue {
      text-decoration: underline;
      text-underline-offset: 4px;
    }
  }
}

.selectedValueContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  min-height: 35px;
  width: -moz-fit-content;
  width: fit-content;
  transition: all $transition-duration $transition-timing-function;
  cursor: pointer;

  &:hover {
    .selectedValue {
      text-decoration: underline;
      text-underline-offset: 4px;
    }

    .selectedValueIcon {
      display: block;
    }
  }
}

.selectedValueIcon {
  display: none;
  height: 16px;

  svg {
    transition: all $transition-duration $transition-timing-function;
    stroke: $gray-60;
    fill: $white-0;
  }

  &:hover {
    svg {
      stroke: $active-side-menu-item;
      fill: $active-side-menu-item;
    }
  }
}
