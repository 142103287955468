.rc-tabs {
  width: 100%;
}

.rc-tabs-tabpane {
  padding: 1.875rem 0 1rem 0 !important;
  min-height: 130px;
  max-height: 130px;
  overflow: auto;
}

.rc-tabs-tabpane::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.rc-tabs-tabpane::-webkit-scrollbar-track {
  border-radius: 4px;
}

.rc-tabs-tabpane::-webkit-scrollbar-thumb {
  background: rgba(179, 179, 179, 0.5);
  border-radius: 4px;
}

.rc-tabs-bar {
  padding: 0 10px;
}

.rc-tabs-tab {
  transition: background-color 0.3s ease;
  font-weight: 400 !important;
  margin-right: 8px !important;
  &:hover {
    background-color: #f5f5f5;
  }
}

.rc-tabs-tab::after {
  display: none !important;
  width: 0;
  height: 0;
}

.rc-tabs-tab-active {
  background-color: #f5f5f5;
  border-radius: 2px;
}

.rc-tabs-ink-bar {
  display: none !important;
}
