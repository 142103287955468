@import 'src/styles/colors';
@import 'src/styles/variables';
@import 'src/styles/elevation';
@import 'src/styles/mixins';
@import '@components/Typography/Typography.module.scss';

table {
  @extend .typography_body-4;
  border-collapse: separate;
  width: 100%;
  border-spacing: 0;
  position: relative;
  background: $background;
}

.table {
  overflow-x: auto;
  overflow-y: scroll;
  background: $background;
  @include scrollbar();
}

.column {
  display: flex;
  gap: 9.5px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.activeRow {
  background-color: $white-10;
}

.actionIcon {
  margin: 0 auto;

  svg {
    path {
      fill: $gray-60;
    }
  }

  &:hover {
    svg {
      path {
        fill: $gray-100;
      }
    }
  }
}

.unit {
  font-weight: 600;
  font-size: 8px;
  letter-spacing: 0.02em;
  line-height: 12px;
}

.sortIcon {
  opacity: 0;
  transition: $transition-duration $transition-timing-function;
  fill: $gray-40;
}

.sortIconActive {
  opacity: 1 !important;
}

thead > tr > .kpiHeaderCell {
  background-color: $white-5 !important;
  border-start-end-radius: 0 !important;
  text-align: right;
  width: calc(100% + 50px);
}

thead > tr > .addColCell {
  div:first-child {
    width: -moz-fit-content !important;
    width: fit-content !important;
    margin-left: auto;
  }
}

thead > tr > .kpiHeaderCell > div:first-child > .sortIcon {
  position: absolute !important;
  right: 0;
  top: 12px;
}

thead > tr > .kpiHeaderCell > div:first-child > span {
  margin-left: auto;
  margin-right: 8px;
}

thead > tr > .dataHeaderCell {
  background-color: $white-0 !important;
}

.dataHeaderCell {
  color: $gray-80;
  font-weight: 300;
}

tbody > tr > th {
  background-color: white !important;
}

.dataHeaderCell + .kpiHeaderCell {
  border-left: 1px solid $white-10;
}

.kpiHeaderCell {
  text-align: right;
  max-width: 126px;
  padding: 12px 0 !important;
  vertical-align: top;
}

:global {
  .ant-table-column-sorters:hover > div {
    opacity: 1;
  }

  .ant-table-cell {
    padding: 12px !important;
    cursor: pointer;
  }

  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right {
    background: unset !important;
  }

  .ant-table-cell:before {
    display: none;
  }

  .ant-table-thead > tr > th {
    border-right: none !important;
    border-color: $white-10;
  }

  .ant-table-tbody > tr > td:nth-child(1) {
    border-right: none !important;
  }

  .ant-table-thead > tr > th {
    border-bottom: 1px solid $gray-20 !important;
  }

  .ant-table-tbody > tr > td {
    transition: none !important;
  }

  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:last-child {
    vertical-align: middle;
  }

  .ant-table-thead > tr > th.dataHeaderCell:last-of-type,
  .ant-table-tbody > tr > td.dataHeaderCell:last-of-type {
    border-right: 1px solid $gray-20 !important;
  }

  .ant-table-container {
    border: none !important;
    border-color: $white-10;
  }

  .ant-table-column-sorters {
    justify-content: flex-start !important;
    gap: 8px;

    .ant-table-column-title {
      flex: unset;
    }
  }

  td.ant-table-column-sort {
    background-color: $white-0 !important;
  }

  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right {
    background-color: $white-0 !important;
  }

  .ant-table-row.ant-table-row-level-0:hover,
  .ant-table-row.ant-table-row-level-0:hover td,
  .ant-table-row.ant-table-row-level-0 .ant-table-cell-row-hover {
    background-color: $white-5 !important;
  }

  .hide-tab {
    display: none !important;
  }

  .ant-dropdown-open > div svg path {
    fill: $gray-100;
  }
}

.tableContainer {
  width: auto;
}

.kpiCellSelection {
  width: 100%;
  min-width: 75px;
  color: $white-0;
  border-radius: 20px;
  background-color: var(--kpiColor);
  opacity: 0.7;
}

.kpiCell {
  padding: 1px 5px;
}

.tabsContainer {
  width: 100%;

  :global {
    .rc-tabs-tab-active {
      color: $gray-100;
      background-color: transparent;
    }

    .rc-tabs-tab {
      font-weight: 600 !important;
      font-size: 12px;
      line-height: 16px;

      &:hover {
        color: $gray-100;
        background-color: transparent;
      }
    }

    .rc-tabs-top.rc-tabs-rtl .rc-tabs-tab {
      margin-left: 0 !important;
    }

    .ant-table-cell-fix-right-first {
      background: inherit;
    }
  }
}

.gridWrapper {
  width: 100%;
}
