@import '@xq/ui-kit/lib/styles/colors.scss';
@import '@xq/ui-kit/lib/styles/variables.scss';

.actionIcon {
  margin: 0 auto;

  svg {
    path {
      fill: $gray-60;
    }
  }

  &:hover {
    svg {
      path {
        fill: $gray-100;
      }
    }
  }
}

.unit {
  font-weight: 600;
  font-size: 8px;
  letter-spacing: 0.02em;
  line-height: 12px;
}

.sortIcon {
  opacity: 0;
  transition: $transition-duration $transition-timing-function;
  fill: $gray-40;
}

.sortIconActive {
  opacity: 1 !important;
}

thead > tr > .kpiHeaderCell {
  background-color: $white-5 !important;
  border-start-end-radius: 0 !important;
  text-align: right;
  width: calc(100% + 50px);
}

thead > tr > .addColCell {
  div:first-child {
    width: -moz-fit-content !important;
    width: fit-content !important;
    margin-left: auto;
  }
}

thead > tr > .kpiHeaderCell > div:first-child > .sortIcon {
  position: absolute !important;
  right: 0;
  top: 12px;
}

thead > tr > .kpiHeaderCell > div:first-child > span {
  margin-left: auto;
  margin-right: 8px;
}

thead > tr > .dataHeaderCell {
  background-color: $white-0 !important;
}

.dataHeaderCell {
  color: $gray-80;
  font-weight: 300;
}

.kpiHeaderCell {
  text-align: right;
  max-width: 126px;
  padding: 12px 0 !important;
  vertical-align: top;
}
