@import '@componentsStyles/colors';
@import '@componentsStyles/variables';

.kpiContainer {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  gap: 4px;
  cursor: pointer;

  &:hover {
    input ~ .checkmark {
      border-color: $gold;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .checkmark {
      background-color: $gold;
      border-color: $gold;
    }

    &:checked ~ .checkmark:after {
      display: block;
    }
  }

  > span {
    white-space: nowrap;
    min-height: 25px;
    display: inline-block;
  }
}

.kpiRadioPlaceholder {
  width: 12px;
}

.checkmark {
  position: relative;
  height: 8px;
  width: 8px;
  background-color: transparent;
  border: 1px solid $gray-100;
  border-radius: 50%;
  transition: $transition-duration $transition-timing-function;
  margin-bottom: -1px;

  &:after {
    content: '';
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: $white-0;
  }
}
